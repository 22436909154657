'use client'

import classnames from 'classnames'
import styles from './HomepageHeroLNY.module.scss'
import { useRef, useEffect } from 'react'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import SanityImage from '@/components/SanityImage/SanityImage'
import Button from '@/components/Button/Button'
import gsap from 'gsap'
import { PortableText } from '@portabletext/react'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'


const HomepageHeroLNY = ({
  className,
  eyebrowImage,
  image,
  mediaType,
  overlayDuration = 6000, // Provide default value to avoid undefined
  overlayOpacity,
  primaryCta,
  secondaryCta,
  subtitle,
  title,
  videoLoopDesktop,
  videoLoopMobile,
}: SanityHomepageHeroLNY) => {
  // Create refs for the elements we need to animate
  const containerRef = useRef<HTMLDivElement | null>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const timelineRef = useRef<gsap.core.Timeline | null>(null)

  useEffect(() => {
    // Create animation only if we have our elements
    if (innerRef.current && overlayRef.current) {
      // Clear any existing timeline
      if (timelineRef.current) {
        timelineRef.current.kill()
      }

      // Set initial state to invisible
      gsap.set([innerRef.current, overlayRef.current], { autoAlpha: 0 })

      // Create the animation timeline
      const tl = gsap.timeline({
        delay: Math.max(0.1, overlayDuration / 1000), // Use overlayDuration as delay, minimum 0.1s
      })

      timelineRef.current = tl

      // Add the overlay fade animation
      tl.to(overlayRef.current, {
        autoAlpha: overlayOpacity / 100,
        duration: 3,
        ease: 'power2.inOut',
      })

      // Add the content fade animation to start at the same time
      tl.to(
        innerRef.current,
        {
          autoAlpha: 1,
          duration: 3,
          ease: 'power2.inOut',
        },
        '<' // '<' means start at the same time as previous animation
      )

      // Cleanup function
      return () => {
        if (timelineRef.current) {
          timelineRef.current.kill()
          timelineRef.current = null
        }
      }
    }
  }, [overlayOpacity, overlayDuration]) // Add overlayDuration to dependencies

  return (
    <div
      className={classnames(styles.HomepageHeroLNY, className)}
      ref={containerRef}
    >
      <div className={styles.inner} ref={innerRef}>
        <div className={styles.content}>
          {eyebrowImage && (
            <AnimateContainer className={styles.imageContainer} animationType="fadeInUp">
              <SanityImage
                image={eyebrowImage}
                className={styles.image}
                breakpoints={{
                  xs: {
                    width: 400,
                    image: eyebrowImage,
                  },
                  tablet: {
                    width: 400,
                    image: eyebrowImage,
                  },
                }}
              />
            </AnimateContainer>
          )}
          {title && (
            <div className={styles.title}>
              <PortableText value={title} />
            </div>
          )}
          {subtitle && (
            <p className={styles.subtitle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <path
                  d="M1.71571 9.28743C1.28273 8.9786 1.14743 8.36096 1.58041 7.79946C2.09457 7.15374 2.87934 6.64839 4.15121 5.4131C2.68991 5.35695 1.30979 5.27273 0.822695 5.13235C0.0920461 4.93583 -0.124443 4.4024 0.064985 3.81283C0.20029 3.25134 0.876817 2.97059 1.4451 3.22326C2.09457 3.44786 2.82522 4.03743 4.25945 4.93583C3.82647 3.19519 3.50174 2.40909 3.47468 1.56684C3.42056 0.893048 3.93472 0.5 4.503 0.5C5.07128 0.5 5.58545 0.893048 5.53132 1.56684C5.50426 2.40909 5.17953 3.25133 4.74655 4.93583C6.01842 4.14973 7.12793 3.39171 7.53384 3.19519C8.21037 2.88636 8.77865 3.25134 8.94102 3.81283C9.13045 4.43048 8.85984 4.93583 8.18331 5.10427C7.66915 5.24465 6.31609 5.32887 4.88186 5.4131C6.07255 6.42379 7.23617 7.37834 7.45266 7.74331C7.88564 8.38903 7.66915 8.9786 7.20911 9.25935C6.83026 9.51203 6.12667 9.56818 5.74781 8.89438C5.50426 8.50133 5.04422 7.32219 4.503 5.72192C3.93472 7.40641 3.50174 8.61363 3.25819 9.00668C2.87934 9.5401 2.20281 9.6524 1.71571 9.28743Z"
                  fill="#D7000F"
                />
              </svg>
             {subtitle}
            </p>
          )}
        </div>
        <div className={styles.ctas}>
          {(primaryCta || secondaryCta) && (
            <div className={styles.buttons}>
              {primaryCta && (
                <Button link={primaryCta} className={styles.primaryCta} primary />
              )}
              {secondaryCta && (
                <Button link={secondaryCta} className={styles.secondaryCta} />
              )}
            </div>
          )}
        </div>
      </div>
      <div className={styles.overlay} ref={overlayRef} />
      <div className={styles.mediaContainer}>
        {mediaType === 'image' && image && (
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              xs: {
                width: 700,
                image,
                options: {
                  height: 1400,
                  fit: 'crop',
                },
              },
              tablet: {
                width: 2000,
                image,
              },
              desktop: {
                width: 2500,
                image,
              },
            }}
          />
        )}
        {mediaType === 'video' && videoLoopMobile && videoLoopDesktop && (
          <VideoLoop
            desktopLoop={videoLoopDesktop}
            mobileLoop={videoLoopMobile}
            desktopSizeMb={3}
            mobileSizeMb={1}
            className={styles.video}
          />
        )}
      </div>
    </div>
  )
}

HomepageHeroLNY.displayName = 'HomepageHeroLNY'

export default HomepageHeroLNY
