'use client'

import classnames from 'classnames'
import styles from './FeaturedTextBlock.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import Button from '@/components/Button/Button'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import { CSSProperties } from 'react'
// import TextAndImageRichText from '@/sections/TextAndImage/TextAndImageRichText'
import { PortableText } from '@portabletext/react'

const FeaturedTextBlock = ({
  className,
  sectionBackground,
  sectionBackgroundImage,
  image,
  title,
  titleColor,
  content,
  primaryCta,
  secondaryCta,
}: FeaturedTextBlockProps) => {
  const backgroundStyle = {
    '--section-background': sectionBackground,
    '--title-color': titleColor,
  } as CSSProperties

  return (
    <section
      className={classnames(styles.FeaturedTextBlock, className)}
      style={backgroundStyle}
    >
      {sectionBackgroundImage && (
        <div className={styles.backgroundImage}>
          <SanityImage
            image={sectionBackgroundImage}
            className={styles.bgImage}
            breakpoints={{
              xs: { width: 800, image: sectionBackgroundImage },
              tablet: { width: 1200, image: sectionBackgroundImage },
              desktop: { width: 2000, image: sectionBackgroundImage },
            }}
          />
        </div>
      )}

      <div className={styles.container}>
        {image && (
          <AnimateContainer className={styles.imageContainer} animationType="fadeInUp">
            <SanityImage
              image={image}
              className={styles.image}
              breakpoints={{
                xs: {
                  width: 75,
                  image,
                },
                tablet: {
                  width: 75,
                  image,
                },
              }}
            />
          </AnimateContainer>
        )}

        <AnimateContainer className={styles.content} animationType="fadeIn">
          {title && (
            <div className={styles.titleWrapper}>
              <PortableText value={title} />
            </div>
          )}

          {content && (
            <div className={styles.textContent}>
              <PortableText value={content} />
            </div>
          )}

          {(primaryCta || secondaryCta) && (
            <div className={styles.ctaContainer}>
              {primaryCta && (
                <Button
                  link={primaryCta}
                  className={styles.primaryCta}
                  primary
                />
              )}
              {secondaryCta && (
                <Button
                  link={secondaryCta}
                  className={styles.secondaryCta}
                />
              )}
            </div>
          )}
        </AnimateContainer>
      </div>
    </section>
  )
}

FeaturedTextBlock.displayName = 'FeaturedTextBlock'

export default FeaturedTextBlock
