'use client'
import { useState, useEffect, useRef, useCallback } from 'react'
import classnames from 'classnames'

import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import JoinWaitlist from '@/components/JoinWaitlist'
import Link from '@/components/Link/Link'
import RestaurantOpenClosedBlurbNewFormat from '@/components/RestaurantOpenClosedBlurbNewFormat/RestaurantOpenClosedBlurbNewFormat'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'

import { DOC_TYPES } from '@/data'

import useBreakpoint from '@/hooks/use-breakpoint'
import useCurrentPage from '@/hooks/use-current-page'
import useI18n from '@/hooks/use-i18n'

import { LOCATION_HERO_INPUT_ID } from '@/sections/LocationHero/LocationHero'
import { ParsedResponse as YelpWaitlistData } from '@/types/yelp'

import { extractYelpBusinessId } from '@/utils/yelp'

import {
  getMergedTimeRangeHoursWithTimeRangeHolidayHours,
  getIfRestaurantIsOpenNewFormat,
  getPrivateDiningId,
  getRestaurantAddressString,
} from '@/utils'

import styles from './LocationsListItem.module.scss'

type LocationsListItem = SanityPage & {
  className?: string
  showPrivateDiningOnly: boolean
  index: number
  totalItems: number
  businessId?: string
  waitlistTime?: { [key: string]: string }
  waitlistStatus?: string
}

const LocationsListItem = ({
  className,
  index,
  locationData,
  showPrivateDiningOnly,
  slug,
  title,
  totalItems,
}: LocationsListItem) => {
  const locationRef = useRef<HTMLLIElement | null>(null)
  const [hasLoadedWaitlist, setHasLoadedWaitlist] = useState(false)
  const [isLoadingWaitlist, setIsLoadingWaitlist] = useState(false)
  const [waitlistData, setWaitlistData] = useState<YelpWaitlistData | null>(null)

  const { i18n } = useI18n()
  const { currentLanguage } = useCurrentPage()
  const { isMobile } = useBreakpoint()

  const addressString = getRestaurantAddressString({
    streetAddress: locationData?.streetAddress,
    city: locationData?.city,
    state: i18n(locationData?.state as string),
    postalCode: locationData?.postalCode,
  })

  // New format: Use weeklyOpeningHours and updated functions
  const adjustedHours = locationData?.weeklyOpeningHours
    ? getMergedTimeRangeHoursWithTimeRangeHolidayHours(locationData.weeklyOpeningHours, locationData?.holidayHours)
    : undefined

  const isRestaurantOpen = locationData?.weeklyOpeningHours && locationData?.timeZone
    ? getIfRestaurantIsOpenNewFormat(locationData.timeZone, adjustedHours!)
    : null

  const locationLink = {
    label: '',
    linkType: 'internal',
    link: {
      _type: DOC_TYPES.LOCATION,
      slug: slug.current,
    },
  }

  const businessId = extractYelpBusinessId(locationData?.reservationLinks?.[0]?.url || '')
  const waitlistTime = waitlistData?.waitEstimates?.['1-2'] ||
    waitlistData?.waitEstimates?.['3-4'] ||
    waitlistData?.waitEstimates?.['5-6'] ||
    waitlistData?.waitEstimates?.['7+'] ||
    ''

  const areaTitle = (
    <span className={styles.areaTitle}>
      <span className={styles.areaTitle__at}>&nbsp;{i18n('at')}</span>&nbsp;{locationData?.areaTitle}
    </span>
  )

  const loadWaitlist = useCallback(async () => {
    setIsLoadingWaitlist(true)

    try {
      const yelpId = businessId

      if (!yelpId) {
        throw new Error('No business ID found')
      }

      if (locationData?.isReservationOnly) {
        throw new Error('Location is reservation only')
      }

      if (!isRestaurantOpen) {
        throw new Error('Restaurant is closed')
      }

      const response = await fetch(`/api/yelp/${yelpId}?location=${yelpId}`)

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      setWaitlistData(data)
    } catch (error) {
      console.error('Error fetching waitlist data:', error)
      setWaitlistData(null)
    } finally {
      setIsLoadingWaitlist(false)
      setHasLoadedWaitlist(true)
    }
  }, [businessId, isRestaurantOpen, locationData?.isReservationOnly])

  useEffect(() => {
    if (!locationRef.current) return

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries
      if (entry.isIntersecting && !hasLoadedWaitlist) {
        loadWaitlist()
      }
    }

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1, // Triggers when at least 10% of the element is visible
    })

    observer.observe(locationRef.current)

    return () => {
      observer.disconnect()
    }
  }, [hasLoadedWaitlist, loadWaitlist, locationRef])

  if (!locationData || !title || !slug?.current) return null

  return (
    <AnimateContainer
      element="li"
      animationType="fadeInUp"
      className={classnames(styles.LocationsListItem, className, { [styles.isComingSoon]: locationData?.isComingSoon })}
      style={{ zIndex: totalItems - index }}
      setImmediate={isMobile === true}
      ref={locationRef}
    >
      <Link
        className={styles.textSide}
        link={locationLink as SanityLink}
      >
        <div className={styles.titlesContainer}>
          <h1 className={styles.title}>
            {title} {locationData?.areaTitle && <span className={styles.areaTitleContainerMobile}>{areaTitle}</span>}
            <span className={styles.hoverRightIcon}>
              <Icon
                name="caretRight"
                className={styles.hoverRightIcon__icon}
              />
            </span>
          </h1>
          {locationData?.areaTitle && <p className={styles.areaTitleContainerDesktop}>{areaTitle}</p>}
          <div className={styles.openCloseBlurbContainerMobile}>
            <RestaurantOpenClosedBlurbNewFormat
              className={styles.openClosedBlurb}
              weeklyOpeningHours={locationData?.weeklyOpeningHours}
              timeZone={locationData?.timeZone}
              holidayHours={locationData.holidayHours}
              isComingSoon={locationData?.isComingSoon}
              isSoftOpening={locationData?.isSoftOpening}
              isTemporarilyClosed={locationData?.isTemporarilyClosed}
            />
          </div>
        </div>
        <div className={styles.phoneNumberAddressContainer}>
          {locationData?.phoneNumber && (
            <p className={styles.phoneNumber}>{i18n('phoneNumberShort', { phoneNumber: locationData?.phoneNumber })}</p>
          )}
          <p className={styles.address}>
            {addressString
              ? i18n('addressShort', {
                address: addressString,
              })
              : i18n('newLocationOpeningSoon')}
          </p>
        </div>
      </Link>
      <div className={styles.blurbColumn}>
        <div className={styles.openCloseBlurbContainerDesktop}>
          <RestaurantOpenClosedBlurbNewFormat
            className={styles.openClosedBlurb}
            weeklyOpeningHours={locationData?.weeklyOpeningHours}
            timeZone={locationData?.timeZone}
            holidayHours={locationData.holidayHours}
            isComingSoon={locationData?.isComingSoon}
            isSoftOpening={locationData?.isSoftOpening}
            isTemporarilyClosed={locationData?.isTemporarilyClosed}
          />
        </div>
      </div>
      <div className={styles.ctaSide}>
        {locationData?.isComingSoon ? (
          <Button
            className={styles.comingSoonButton}
            label={i18n('notifyMeWhenOpening')}
            link={locationLink as SanityLink}
            secondary
            icon="caretRight"
            onClick={() => {
              setTimeout(() => {
                const input = document.getElementById(LOCATION_HERO_INPUT_ID)
                if (input) input.focus()
              }, 150)
            }}
          />
        ) : (
          <>
            <div
              className={classnames(styles.buttons, {
                [styles.privateDiningOnlyButtons]: showPrivateDiningOnly,
              })}
            >
              <div className={styles.topButtons}>
                {locationData?.reservationLinks?.length && !showPrivateDiningOnly && (
                  <OrderDropdown
                    className={styles.reserveButton}
                    serviceLinks={locationData?.reservationLinks}
                    dropdownPosition="bottomRight"
                    isReserve
                    primary
                    slug={slug?.current}
                  />
                )}
                {locationData?.serviceLinks?.length && !locationData?.isSoftOpening && (
                  <OrderDropdown
                    className={styles.orderButton}
                    serviceLinks={locationData?.serviceLinks}
                    dropdownPosition="bottomRight"
                  />
                )}
              </div>
              <div className={styles.bottomButtons}>
                {showPrivateDiningOnly && (
                  <Button
                    primary
                    link={
                      {
                        label: i18n('details'),
                        linkType: 'internal',
                        link: {
                          _type: DOC_TYPES.LOCATION,
                          slug: slug.current,
                        },
                        hash: getPrivateDiningId(currentLanguage as string, slug.current),
                      } as SanityLink
                    }
                    icon="caretRight"
                  />
                )}
                {!locationData?.isReservationOnly && isRestaurantOpen && businessId && !showPrivateDiningOnly && !locationData?.isSoftOpening && waitlistTime && (
                  <JoinWaitlist
                    waitlistTime={waitlistTime}
                    secondary
                    className={styles.joinWaitlistButton}
                    businessId={businessId}
                    waitlistStatus={isLoadingWaitlist ? 'LOADING' : waitlistData?.waitlistStatus}
                    closedReason={waitlistData?.closedReason}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </AnimateContainer>
  )
}

LocationsListItem.displayName = 'LocationsListItem'

export default LocationsListItem
