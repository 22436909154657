'use client'

import { Fragment, useState, type ElementType, useEffect, useRef } from 'react'

import SectionContainer from '@/components/SectionContainer/SectionContainer'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { buildIdFromText } from '@/utils'

/* INJECT_SECTIONS_IMPORT */
import AccordionItemsWithSideNavigation from '@/sections/AccordionItemsWithSideNavigation/AccordionItemsWithSideNavigation'
import BlogPost from '@/sections/BlogPost/BlogPost'
import ContactForm from '@/sections/ContactForm/ContactForm'
import ContentCards from '@/sections/ContentCards/ContentCards'
import EcommerceItemList from '@/sections/EcommerceItemList/EcommerceItemList'
import EmailSignup from '@/sections/EmailSignup/EmailSignup'
import EventDetails from '@/sections/EventDetails/EventDetails'
import FeaturedTextBlock from '@/sections/FeaturedTextBlock/FeaturedTextBlock'
import Footer from '@/components/Footer/Footer'
import FourOhFour from '@/sections/FourOhFour/FourOhFour'
import GiftCardGrid from '@/sections/GiftCardGrid/GiftCardGrid'
import GiftCardIframe from '@/sections/GiftCardIframe/GiftCardIframe'
import GuestServicesHero from '@/sections/GuestServicesHero/GuestServicesHero'
import HomepageHero from '@/sections/HomepageHero/HomepageHero'
import HomepageHeroLNY from '@/sections/HomepageHeroLNY/HomepageHeroLNY'
import LocationHero from '@/sections/LocationHero/LocationHero'
import LocationsList from '@/sections/LocationsList/LocationsList'
import MediaBackgroundAndTitle from '@/sections/MediaBackgroundAndTitle/MediaBackgroundAndTitle'
import MenuListing from '@/sections/MenuListing/MenuListing'
import MerchandiseListing from '@/sections/MerchandiseListing/MerchandiseListing'
import MerchandiseShowcase from '@/sections/MerchandiseShowcase/MerchandiseShowcase'
import PressHighlightListing from '@/sections/PressHighlightListing/PressHighlightListing'
import Reviews from '@/sections/Reviews/Reviews'
import RichTextContent from '@/sections/RichTextContent/RichTextContent'
import RichTextHero from '@/sections/RichTextHero/RichTextHero'
import StaggeredImages from '@/sections/StaggeredImages/StaggeredImages'
import Stats from '@/sections/Stats/Stats'
import TextAndAccordion from '@/sections/TextAndAccordion/TextAndAccordion'
import TextAndImage from '@/sections/TextAndImage/TextAndImage'
import TextAndImageHeroWithBreadcrumbs from '@/sections/TextAndImageHeroWithBreadcrumbs/TextAndImageHeroWithBreadcrumbs'
import ThreeUp from '@/sections/ThreeUp/ThreeUp'
import Timeline from '@/sections/Timeline/Timeline'
import ValuesList from '@/sections/ValuesList/ValuesList'

import { SectionsProps } from '@/types/components/SectionsProps'


gsap.registerPlugin(ScrollTrigger)

ScrollTrigger.config({
  ignoreMobileResize: true,
})

const SECTIONS: {
  [key: string]: ElementType
} = {
  /* INJECT_SECTIONS_COMPONENT_TYPE */
  accordionItemsWithSideNavigation: AccordionItemsWithSideNavigation,
  blogPost: BlogPost,
  contactForm: ContactForm,
  contentCards: ContentCards,
  ecommerceItemList: EcommerceItemList,
  eventDetails: EventDetails,
  emailSignup: EmailSignup,
  featuredTextBlock: FeaturedTextBlock,
  fourOhFour: FourOhFour,
  guestServicesHero: GuestServicesHero,
  giftCardGrid: GiftCardGrid,
  giftCardIframe: GiftCardIframe,
  homepageHero: HomepageHero,
  homepageHeroLNY: HomepageHeroLNY,
  locationHero: LocationHero,
  locationsList: LocationsList,
  mediaBackgroundAndTitle: MediaBackgroundAndTitle,
  menuListing: MenuListing,
  merchandiseListing: MerchandiseListing,
  merchandiseShowcase: MerchandiseShowcase,
  pressHighlightListing: PressHighlightListing,
  reviews: Reviews,
  richTextContent: RichTextContent,
  staggeredImages: StaggeredImages,
  stats: Stats,
  richTextHero: RichTextHero,
  textAndAccordion: TextAndAccordion,
  textAndImage: TextAndImage,
  textAndImageHeroWithBreadcrumbs: TextAndImageHeroWithBreadcrumbs,
  timeline: Timeline,
  threeUp: ThreeUp,
  valuesList: ValuesList,
}

function Sections({ sections, yelpData }: SectionsProps) {
  const mounted = useRef(false)

  const sectionsFiltered = sections.filter(
    sectionArr => !sectionArr?.section[0]?.cmsSettings?.isHidden && sectionArr?.section,
  )
  const [allowSectionBackgroundChange, setAllowSectionBackgroundChange] = useState(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      const hash = window.location.hash
      if (!hash) return

      const id = hash.replace('#', '')
      let attempts = 0
      const maxAttempts = 50 // 5 seconds total

      const tryScroll = () => {
        const element = document.getElementById(id)
        if (element) {
          // Add a small delay to ensure layout is complete
          setTimeout(() => {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
            // Backup scroll in case smooth scroll fails
            window.scrollTo({
              top: element.offsetTop,
              behavior: 'smooth',
            })
          }, 500) // Half second delay
          return true
        }
        return false
      }

      const attemptScroll = () => {
        if (attempts >= maxAttempts) return

        if (!tryScroll()) {
          attempts++
          setTimeout(attemptScroll, 100)
        }
      }

      // Start attempts after initial render
      setTimeout(attemptScroll, 100)
    }
  }, [])

  if (!sectionsFiltered?.length) return null

  return (
    <>
      {sectionsFiltered.map((sectionArr, i) => {
        if (!sectionArr?.section) {
          console.warn('No sections tied to data ', sectionArr)
          return null
        }

        const prevSectionObj = { ...sectionsFiltered[i - 1]?.section[0], yelpData }
        const nextSectionObj = { ...sectionsFiltered[i + 1]?.section[0], yelpData }
        const sectionObj = { ...sectionArr?.section[0], ...sectionArr?.section[0]?.section?.[0], yelpData }

        if (!sectionObj._type) {
          console.warn('No type found for section ', sectionObj)
          return null
        }

        if (!SECTIONS[sectionObj._type]) {
          console.warn('No section found with type ', sectionObj._type)
          return null
        }

        if (sectionObj?.cmsSettings?.isHidden) {
          console.warn('Section is hidden ', sectionObj)
          return null
        }

        const SectionComponent = SECTIONS[sectionObj._type]

        /* eslint-disable */
        const prevSectionBackground = (prevSectionObj as any)?.sectionBackground
        const currentSectionBackground = (sectionObj as any)?.sectionBackground ?? 'black'
        const nextSectionBackground = (nextSectionObj as any)?.sectionBackground
        const nextSectionType = (nextSectionObj as any)?._type || undefined
        /* eslint-enable */

        return (
          <Fragment key={`${i}_${sectionObj._id}_${buildIdFromText(sectionObj?.cmsSettings?.cmsTitle || '')}`}>
            <SectionContainer
              cmsTitle={sectionObj?.cmsSettings?.cmsTitle || ''}
              _type={sectionObj._type}
              sectionsLength={sectionsFiltered.length}
              sectionBackground={currentSectionBackground}
              previousSectionBackground={prevSectionBackground}
              nextSectionBackground={nextSectionBackground}
              nextSectionType={nextSectionType}
              sectionIndex={i}
              allowSectionBackgroundChange={allowSectionBackgroundChange}
              setAllowSectionBackgroundChange={setAllowSectionBackgroundChange}
            >
              <SectionComponent {...sectionObj} />
            </SectionContainer>
            {i === sectionsFiltered.length - 1 && (
              <SectionContainer
                sectionIndex={i + 1}
                cmsTitle={'footer'}
                _type={'footer'}
                sectionsLength={sectionsFiltered.length}
                sectionBackground={'black'}
                previousSectionBackground={currentSectionBackground}
                nextSectionBackground={'dough'}
                nextSectionType={undefined}
                allowSectionBackgroundChange={allowSectionBackgroundChange}
                setAllowSectionBackgroundChange={setAllowSectionBackgroundChange}
              >
                <Footer />
              </SectionContainer>
            )}
          </Fragment>
        )
      })}
    </>
  )
}

export default Sections
