'use client'

import classnames from 'classnames'
import styles from './MediaBackgroundAndTitle.module.scss'
import { CSSProperties } from 'react'
import HeaderWithCtas from '@/components/HeaderWithCtas/HeaderWithCtas'
import AnimateContainer from '@/components/AnimateContainer/AnimateContainer'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import SanityImage from '@/components/SanityImage/SanityImage'

const MediaBackgroundAndTitle = ({
  className,
  headingContent,
  eyebrow,
  mediaType,
  overlayOpacity,
  image,
  videoLoopDesktop,
  videoLoopMobile,
  textColor,
  sectionIndex,
}: SanityMediaBackgroundAndTitle) => {
  if (
    !headingContent ||
    !mediaType ||
    (mediaType === 'image' && !image?.asset?.url) ||
    (mediaType === 'video' && !videoLoopDesktop?.asset?.url) ||
    (mediaType === 'video' && !videoLoopMobile?.asset?.url)
  ) {
    return null
  }

  return (
    <div
      className={classnames(styles.MediaBackgroundAndTitle, className)}
      style={
        {
          '--overlay-opacity': overlayOpacity / 100,
        } as CSSProperties
      }
      data-align-items={headingContent.alignItems}
      data-text-color={textColor}
    >
      <div className={styles.inner}>
        <AnimateContainer
          animationType="fadeInUp"
          className={classnames(styles.content, {
            [styles.narrowContainer]: headingContent.hasNarrowContainer,
          })}
          setImmediate={sectionIndex === 0 ? true : false}
        >
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <HeaderWithCtas {...headingContent} />
        </AnimateContainer>
      </div>
      <div className={styles.overlay} />
      <div className={styles.mediaContainer}>
        {mediaType === 'image' && image && (
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              xs: {
                width: 700,
                image,
                options: {
                  height: 1400,
                  fit: 'crop',
                },
              },
              tablet: {
                width: 2000,
                image,
              },
              desktop: {
                width: 2500,
                image,
              },
            }}
          />
        )}
        {mediaType === 'video' && videoLoopMobile && videoLoopDesktop && (
          <VideoLoop
            desktopLoop={videoLoopDesktop}
            mobileLoop={videoLoopMobile}
            desktopSizeMb={3}
            mobileSizeMb={0.75}
            className={styles.video}
          />
        )}
      </div>
    </div>
  )
}

MediaBackgroundAndTitle.displayName = 'MediaBackgroundAndTitle'

export default MediaBackgroundAndTitle
