import React from 'react'
import styles from './FormResults.module.scss'

interface FormResultProps {
  errorMessage?: React.ReactNode
  id?: string
  status: 'success' | 'error'
  successMessage?: React.ReactNode
  variant?: 'default' | 'neutral'
}

const FormResult = ({ errorMessage, successMessage, status, variant = 'default', id }: FormResultProps) => {
  const isSuccess = status === 'success'
  const className = isSuccess ? styles.FormResultsSuccess : styles.FormResultsError

  return (
    <div
      className={styles.FormResults}
      id={id}
    >
      <div className={`${className} ${variant === 'neutral' ? styles.FormResultsNeutral : ''}`}>
        {isSuccess ? successMessage : errorMessage}
      </div>
    </div>
  )
}

export default FormResult
