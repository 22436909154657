'use client'

import classnames from 'classnames'
import styles from './RestaurantOpenClosedBlurb.module.scss'
import { getIfRestaurantIsOpen, getMergedHoursWithHolidayHours, getRestaurantClosingHour } from '@/utils'
import useI18n from '@/hooks/use-i18n'

type RestaurantOpenClosedBlurb = {
  timeZone?: string
  hours?: hoursByDay
  className?: string
  holidayHours: SanityLocationHolidayHours[]
  isComingSoon?: boolean
  isSoftOpening?: boolean
  isTemporarilyClosed?: boolean
}

const RestaurantOpenClosedBlurb = ({
  className,
  timeZone,
  hours,
  holidayHours,
  isComingSoon,
  isSoftOpening,
  isTemporarilyClosed,
}: RestaurantOpenClosedBlurb) => {
  const { i18n } = useI18n()

  if (isTemporarilyClosed) {
    return <div className={classnames(styles.RestaurantOpenClosedBlurb, className)} data-is-open={'false'} data-is-coming-soon={isComingSoon === true} >
      <span className={styles.dot} />
      <span className={styles.text}>Temporarily Closed</span>
    </div>
  }
  const hoursWithHolidayHours = getMergedHoursWithHolidayHours(hours as hoursByDay, holidayHours)
  const closingHour = getRestaurantClosingHour(timeZone, hoursWithHolidayHours)
  const isOpen = getIfRestaurantIsOpen(timeZone, hoursWithHolidayHours)

  let text = i18n('locationOpenUntil', { time: closingHour })
  if (!isOpen) {
    text = i18n('locationClosed')
  }
  if (isComingSoon === true || isSoftOpening === true) {
    text = i18n('steamingSoon')
  }

  if (!timeZone || !hoursWithHolidayHours) return null

  return (
    <div
      className={classnames(styles.RestaurantOpenClosedBlurb, className)}
      data-is-open={isOpen ? 'true' : 'false'}
      data-is-coming-soon={isComingSoon === true || isSoftOpening === true}
    >
      <span className={styles.dot} />
      <span className={styles.text}>{text}</span>
    </div>
  )
}

RestaurantOpenClosedBlurb.displayName = 'RestaurantOpenClosedBlurb'

export default RestaurantOpenClosedBlurb
