'use client'

import classnames from 'classnames'
import styles from './LocationHeroHoursNewFormat.module.scss'
import { formatMilitaryTimeWithoutMinutes } from '@/utils'
import { useEffect, useState } from 'react'
import useI18n from '@/hooks/use-i18n'
import RestaurantOpenClosedBlurbNewFormat from '@/components/RestaurantOpenClosedBlurbNewFormat/RestaurantOpenClosedBlurbNewFormat'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Icon from '@/components/Icon/Icon'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

type LocationHeroHoursNewFormatProps = Pick<SanityLocationData, 'timeZone' | 'weeklyOpeningHours' | 'holidayHours'> & {
  className?: string
  isTemporarilyClosed?: boolean
}

const LocationHeroHoursNewFormat = ({
  className,
  timeZone,
  weeklyOpeningHours,
  holidayHours,
  isTemporarilyClosed,
}: LocationHeroHoursNewFormatProps) => {
  const [holidayHoursFiltered, setHolidayHoursFiltered] = useState<SanityLocationHolidayHours[]>([])
  const [currentWeekHolidays, setCurrentWeekHolidays] = useState<{ [key: string]: SanityLocationHolidayHours }>({})
  const { i18n } = useI18n()
  const [holidayHoursOpen, setHolidayHoursOpen] = useState(false)

  // Day names in order
  const dayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  // Filter holiday hours within the next 30 days
  useEffect(() => {
    const msSecond = 1000
    const msInMonth = msSecond * 60 * 60 * 24 * 30
    const todayTime = new Date().getTime()
    const _holidayHoursFiltered = holidayHours.filter(item => {
      const holidayDate = new Date(item.date)
      const holidayDateTime = holidayDate.getTime()
      const isPastCurrentDate = todayTime < holidayDateTime
      const isWithin30Days = holidayDateTime - todayTime > 0 && holidayDateTime - todayTime < msInMonth
      return isPastCurrentDate && isWithin30Days
    })
    setHolidayHoursFiltered(_holidayHoursFiltered)
  }, [holidayHours])

  // Identify holidays in the current week
  useEffect(() => {
    const today = new Date()
    const weekHolidays: { [key: string]: SanityLocationHolidayHours } = {}

    // Calculate the next 7 days
    for (let i = 0; i < 7; i++) {
      const date = new Date(today)
      date.setDate(today.getDate() + i)
      const formattedDate = date.toISOString().split('T')[0]

      // Find holiday for this date
      const holidayForDate = holidayHours.find(holiday => holiday.date === formattedDate)
      if (holidayForDate) {
        // Convert JavaScript's getDay() to our dayNames index
        // getDay(): 0=Sunday, 1=Monday, etc.
        // dayNames: 0=Monday, 1=Tuesday, etc.
        const dayIndex = date.getDay() === 0 ? 6 : date.getDay() - 1
        const dayOfWeek = dayNames[dayIndex]
        weekHolidays[dayOfWeek] = holidayForDate
      }
    }

    setCurrentWeekHolidays(weekHolidays)
  }, [holidayHours])

  // Format multiple time ranges into a display string
  const formatTimeRanges = (timeRanges: OpenCloseHours[]) => {
    if (!timeRanges || timeRanges.length === 0) {
      return i18n('locationClosed')
    }

    if (timeRanges[0].isClosed) {
      return i18n('locationClosed')
    }

    return timeRanges.map(range => {
      return `${formatMilitaryTimeWithoutMinutes(range.open)} - ${formatMilitaryTimeWithoutMinutes(range.close)}`
    }).join(', ')
  }

  // Format holiday hours (either from openingHours array or legacy fields)
  const formatHolidayHours = (holiday: SanityLocationHolidayHours) => {
    if (holiday.isClosed) {
      return i18n('locationClosed')
    }

    if (holiday.openingHours && holiday.openingHours.length > 0) {
      return formatTimeRanges(holiday.openingHours)
    }

    if (holiday.openingHour && holiday.closingHour) {
      return `${formatMilitaryTimeWithoutMinutes(holiday.openingHour)} - ${formatMilitaryTimeWithoutMinutes(holiday.closingHour)}`
    }

    return ''
  }

  if (!weeklyOpeningHours || !timeZone) return null


  return (
    <div className={classnames(styles.LocationHeroHours, className)}>
      <RestaurantOpenClosedBlurbNewFormat
        className={styles.openClosedBlurb}
        weeklyOpeningHours={weeklyOpeningHours}
        holidayHours={holidayHours}
        timeZone={timeZone}
        isTemporarilyClosed={isTemporarilyClosed}
      />
      <ul className={styles.dayHoursList}>
        {dayNames.map((day) => {
          const hasHoliday = !!currentWeekHolidays[day]
          const holiday = currentWeekHolidays[day]
          const dayHours = weeklyOpeningHours[day as keyof WeeklyOpeningHours]
          const formattedHours = formatTimeRanges(dayHours)

          // Format day name (capitalize first letter and add asterisk if needed)
          const dayNameShort = i18n(`${day}Short`) || day.substring(0, 3).toUpperCase()

          return (
            <li key={day} className={styles.dayHoursList__item}>
              <div className={styles.dayInfo}>
                <p className={styles.dayHoursList__item__day}>
                  {dayNameShort}
                  {hasHoliday && <span className={styles.dayAsterisk}>*</span>}
                </p>
                {hasHoliday && (
                  <p className={styles.holidayName}>
                    {holiday.title}
                  </p>
                )}
              </div>
              <div className={styles.hoursInfo}>
                <p className={styles.dayHoursList__item__hours}>{formattedHours}</p>
                {hasHoliday && (
                  <p className={styles.holidayHours}>
                    {formatHolidayHours(holiday)}
                  </p>
                )}
              </div>
            </li>
          )
        })}
      </ul>
      {holidayHoursFiltered?.length > 0 && (
        <div
          className={styles.holidayHoursContainer}
          data-holidays-opened={holidayHoursOpen}
        >
          <button
            className={styles.titleButton}
            onClick={() => {
              setHolidayHoursOpen(prev => !prev)
            }}
          >
            <span className={styles.titleButton__text}>{i18n('holidayOpeningHours')}</span>
            <span className={styles.titleButton__iconContainer}>
              <Icon
                className={styles.titleButton__icon}
                name="caretDown"
              />
            </span>
          </button>
          {holidayHoursOpen && (
            <ul className={styles.holidayHoursList}>
              {holidayHoursFiltered.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={styles.holidayHoursList__item}
                  >
                    <p className={styles.holidayHoursList__itemTitle}>{item.title}<span className={styles.dayAsterisk}>*</span></p>
                    <p className={styles.holidayHoursList__itemHours}>
                      {formatHolidayHours(item)}
                    </p>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

LocationHeroHoursNewFormat.displayName = 'LocationHeroHoursNewFormat'

export default LocationHeroHoursNewFormat
