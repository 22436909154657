'use client'

import classnames from 'classnames'
import styles from './JoinWaitlist.module.scss'
import Button from '@/components/Button/Button'
import useI18n from '@/hooks/use-i18n'
import { useMemo } from 'react'

interface JoinWaitlistProps {
  className?: string
  waitlistTime: string
  primary?: boolean
  secondary?: boolean
  onClick?: () => void
  businessId?: string
  waitlistStatus?: string
  closedReason?: string | null
}

const JoinWaitlist = ({
  className,
  waitlistTime,
  primary = false,
  secondary,
  onClick,
  businessId,
  waitlistStatus,
  closedReason,
}: JoinWaitlistProps) => {
  const { i18n } = useI18n()

  const buttonLabel = useMemo(() => {
    if (waitlistStatus === 'LOADING') {
      return i18n('joinWaitlistLoading')
    }


    if (!waitlistStatus || (waitlistStatus === 'CLOSED' && closedReason !== 'no_current_wait')) {
      return i18n('joinWaitlistClosed')
    }

    const waitTimeNumber = Number(waitlistTime)
    const hasWaitTime = waitlistTime && waitTimeNumber !== 0

    return hasWaitTime
      ? `${i18n('joinWaitlist', { time: waitlistTime })} ${waitlistTime} mins`
      : i18n('joinWaitlistFree')
  }, [i18n, waitlistStatus, closedReason, waitlistTime])

  const buttonLink = useMemo(() =>
    businessId
      ? {
        link: `https://www.yelp.com/waitlist/${businessId}`,
        linkType: 'external' as const,
      }
      : undefined
    , [businessId])

  return (
    <div className={classnames(styles.JoinWaitlist, className)}>
      <Button
        onClick={onClick}
        primary={primary}
        secondary={secondary ?? !primary}
        label={buttonLabel}
        link={buttonLink}
        className={styles.button}
      />
    </div>
  )
}

JoinWaitlist.displayName = 'JoinWaitlist'

export default JoinWaitlist
